export const TRACKING: any = {
  HOME: {
    BUTTON: {
      SEE_LOCATIONS: {
        action: 'CLICK',
        label: 'HOME.BUTTON.SEE_LOCATIONS',
      },
      DELIVERY: {
        action: 'CLICK',
        label: 'HOME.BUTTON.DELIVERY',
      },
      MENU: {
        action: 'CLICK',
        label: 'HOME.BUTTON.MENU',
      },
      RESERVATION: {
        action: 'CLICK',
        label: 'HOME.BUTTON.RESERVATION',
      },
      FEEDBACK: {
        action: 'CLICK',
        label: 'HOME.BUTTON.FEEDBACK',
      },
      CUSTOMIZED_BUTTON: {
        action: 'CLICK',
        label: 'HOME.BUTTON.CUSTOMIZED_BUTTON',
      },
      SURVEY: {
        action: 'CLICK',
        label: 'HOME.BUTTON.SURVEY',
      },
    },
    BUTTON_MAPPED: {
      Menu: 'MENU',
      Reservation: 'RESERVATION',
      Feedback: 'FEEDBACK',
      Delivery: 'DELIVERY',
      Survey: 'SURVEY',
    },
    PARTNER: {
      INSTAGRAM: {
        action: 'CLICK',
        label: 'HOME.PARTNER.INSTAGRAM',
      },
      FACEBOOK: {
        action: 'CLICK',
        label: 'HOME.PARTNER.FACEBOOK',
      },
      MESSENGER: {
        action: 'CLICK',
        label: 'HOME.PARTNER.MESSENGER',
      },
      GOOGLE: {
        action: 'CLICK',
        label: 'HOME.PARTNER.GOOGLE',
      },
      LINKEDIN: {
        action: 'CLICK',
        label: 'HOME.PARTNER.LINKEDIN',
      },
      TIKTOK: {
        action: 'CLICK',
        label: 'HOME.PARTNER.TIKTOK',
      },
      YELP: {
        action: 'CLICK',
        label: 'HOME.PARTNER.YELP',
      },
      DOORDASH: {
        action: 'CLICK',
        label: 'HOME.PARTNER.DOORDASH',
      },
      YOUTUBE: {
        action: 'CLICK',
        label: 'HOME.PARTNER.YOUTUBE',
      },
      WHATSAPP: {
        action: 'CLICK',
        label: 'HOME.PARTNER.WHATSAPP',
      },
      SNAPCHAT: {
        action: 'CLICK',
        label: 'HOME.PARTNER.SNAPCHAT',
      },
      TWITTER: {
        action: 'CLICK',
        label: 'HOME.PARTNER.TWITTER',
      },
      PINTEREST: {
        action: 'CLICK',
        label: 'HOME.PARTNER.PINTEREST',
      },
      LINE: {
        action: 'CLICK',
        label: 'HOME.PARTNER.LINE',
      },
      SPOTIFY: {
        action: 'CLICK',
        label: 'HOME.PARTNER.SPOTIFY',
      },
      TRIP_ADVISOR: {
        action: 'CLICK',
        label: 'HOME.PARTNER.TRIP_ADVISOR',
      },
      GOOGLE_PLAY_STORE: {
        action: 'CLICK',
        label: 'HOME.PARTNER.GOOGLE_PLAY_STORE',
      },
      APP_STORE: {
        action: 'CLICK',
        label: 'HOME.PARTNER.APP_STORE',
      },
    },

    SUBSCRIBE: {
      SUBSCRIBE: {
        action: 'CLICK',
        label: 'HOME.SUBSCRIBE.SUBSCRIBE',
      },
      FIRST_LAST_NAME: {
        action: 'CLICK',
        label: 'HOME.SUBSCRIBE.FIRST_LAST_NAME',
      },
      EMAIL: {
        action: 'CLICK',
        label: 'HOME.SUBSCRIBE.EMAIL',
      },
      PHONE_NUMBER: {
        action: 'CLICK',
        label: 'HOME.SUBSCRIBE.PHONE_NUMBER',
      },
      DATE_OF_BIRTH: {
        action: 'CLICK',
        label: 'HOME.SUBSCRIBE.DATE_OF_BIRTH',
      },
      TYPE_FIRST_LAST_NAME: {
        action: 'TYPE',
        label: 'HOME.SUBSCRIBE.TYPE_FIRST_LAST_NAME',
      },
      TYPE_EMAIL: {
        action: 'TYPE',
        label: 'HOME.SUBSCRIBE.TYPE_EMAIL',
      },
      TYPE_PHONE_NUMBER: {
        action: 'TYPE',
        label: 'HOME.SUBSCRIBE.TYPE_PHONE_NUMBER',
      },
      TYPE_DATE_OF_BIRTH: {
        action: 'TYPE',
        label: 'HOME.SUBSCRIBE.TYPE_DATE_OF_BIRTH',
      },
      TERMS_AND_CONDITIONS: {
        action: 'CLICK',
        label: 'HOME.SUBSCRIBE.TERMS_AND_CONDITIONS',
      },
      PRIVACY_POLICY: {
        action: 'CLICK',
        label: 'HOME.SUBSCRIBE.PRIVACY_POLICY',
      },
      ACCEPT_MOMOS_TERMS: {
        action: 'CLICK',
        label: 'HOME.SUBSCRIBE.ACCEPT_MOMOS_TERMS',
      },
      ACCEPT_BRAND_TERMS: {
        action: 'CLICK',
        label: 'HOME.SUBSCRIBE.ACCEPT_BRAND_TERMS',
      },
      SUBSCRIBE_NOW: {
        action: 'CLICK',
        label: 'HOME.SUBSCRIBE.SUBSCRIBE_NOW',
      },
      SUBSCRIBED: {
        action: 'CLICK',
        label: 'HOME.SUBSCRIBE.SUBSCRIBED',
      },
      MODAL_CLOSE: {
        action: 'CLICK',
        label: 'HOME.SUBSCRIBE.MODAL_CLOSE',
      },
      UNSUBSCRIBE_MODAL_CLOSE: {
        action: 'CLICK',
        label: 'HOME.SUBSCRIBE.UNSUBSCRIBE_MODAL_CLOSE',
      },
    },

    UNSUBSCRIBE: {
      UNSUBSCRIBE: {
        action: 'CLICK',
        label: 'HOME.UNSUBSCRIBE.UNSUBSCRIBE',
      },

      UNSUBSCRIBE_NOW: {
        action: 'CLICK',
        label: 'HOME.UNSUBSCRIBE.UNSUBSCRIBE_NOW',
      },
      CANCEL: {
        action: 'CLICK',
        label: 'HOME.UNSUBSCRIBE.CANCEL',
      },
      MODAL_CLOSE: {
        action: 'CLICK',
        label: 'HOME.UNSUBSCRIBE.MODAL_CLOSE',
      },
    },
  },

  MENU: {
    BUTTON: {
      MAKE_A_RESERVATION: {
        action: 'CLICK',
        label: 'MENU.BUTTON.MAKE_A_RESERVATION',
      },
      ORDER_FOR_DELIVERY: {
        action: 'CLICK',
        label: 'MENU.BUTTON.ORDER_FOR_DELIVERY',
      },
    },
    MENU_ITEM: {
      ITEM: {
        action: 'CLICK',
        label: 'MENU.MENU_ITEM.ITEM',
      },
    },
  },

  TNC: {
    BUTTON: {
      VIEW_DETAIL: {
        action: 'CLICK',
        label: 'TNC.BUTTON.VIEW_DETAIL',
      },
    },
  },

  ORDER_FOR_DELIVERY: {
    PLATFORM: {
      action: 'CLICK',
      label: 'ORDER_FOR_DELIVERY.SELECT_PLATFORM',
    },
    LOCATION: {
      action: 'CLICK',
      label: 'ORDER_FOR_DELIVERY.SELECT_LOCATION',
    },
    SELECT_PLATFORM: {
      GRAB: {
        action: 'CLICK',
        label: 'ORDER_FOR_DELIVERY.SELECT_PLATFORM.GRAB',
      },
      DELIVEROO: {
        action: 'CLICK',
        label: 'ORDER_FOR_DELIVERY.SELECT_PLATFORM.DELIVEROO',
      },
      WEBSITE: {
        action: 'CLICK',
        label: 'ORDER_FOR_DELIVERY.SELECT_PLATFORM.WEBSITE',
      },
      FOODPANDA: {
        action: 'CLICK',
        label: 'ORDER_FOR_DELIVERY.SELECT_PLATFORM.FOODPANDA',
      },
      UBER: {
        action: 'CLICK',
        label: 'ORDER_FOR_DELIVERY.SELECT_PLATFORM.UBER',
      },
      DOORDASH: {
        action: 'CLICK',
        label: 'ORDER_FOR_DELIVERY.SELECT_PLATFORM.DOORDASH',
      },
      YELP: {
        action: 'CLICK',
        label: 'ORDER_FOR_DELIVERY.SELECT_PLATFORM.YELP',
      },
      GRUBHUB: {
        action: 'CLICK',
        label: 'ORDER_FOR_DELIVERY.SELECT_PLATFORM.GRUBHUB',
      },
    },
    SELECT_LOCATION: {
      LOCATION: {
        action: 'CLICK',
        label: 'ORDER_FOR_DELIVERY.SELECT_LOCATION.LOCATION',
      },
    },
  },
  MAKE_A_RESERVATION: {
    PLATFORM: {
      action: 'CLICK',
      label: 'MAKE_A_RESERVATION.SELECT_PLATFORM',
    },
    LOCATION: {
      action: 'CLICK',
      label: 'MAKE_A_RESERVATION.SELECT_LOCATION',
    },
  },

  LOCATION: {
    ORDER_FOR_DELIVERY: {
      action: 'CLICK',
      label: 'LOCATION.ORDER_FOR_DELIVERY',
    },
    MAKE_A_RESERVATION: {
      action: 'CLICK',
      label: 'LOCATION.MAKE_A_RESERVATION',
    },
    OPEN_A_PLATFORM: {
      action: 'CLICK',
      label: 'LOCATION.OPEN_A_PLATFORM',
    },
  },

  OPEN_PLATFROM: {
    action: 'CLICK',
    label: 'OPEN_PLATFROM.LOCATION',
  },

  VOUCHERS: {
    DISPENSE_VOUCHER: {
      action: 'CLICK',
      label: 'VOUCHERS.DISPENSE_VOUCHER',
    },
    LUCKY_DRAW_VOUCHER: {
      action: 'CLICK',
      label: 'VOUCHERS.LUCKY_DRAW_VOUCHER',
    },
    REDEEM_VOUCHER: {
      action: 'CLICK',
      label: 'VOUCHERS.REDEEM_VOUCHER',
    },
    REDEEM: {
      ENTER_PIN: {
        action: 'CLICK',
        label: 'VOUCHERS.REDEEM_VOUCHER.ENTER_PIN',
      },
      SUBMIT_PIN: {
        action: 'CLICK',
        label: 'VOUCHERS.REDEEM_VOUCHER.SUBMIT_PIN',
      },
      SEARCH_LOCATON: {
        action: 'CLICK',
        label: 'VOUCHERS.REDEEM_VOUCHER.SEARCH_LOCATON',
      },
      SELECT_LOCATON: {
        action: 'CLICK',
        label: 'VOUCHERS.REDEEM_VOUCHER.SELECT_LOCATON',
      },
      AUTO_SELECT_LOCATION: {
        action: 'CLICK',
        label: 'VOUCHERS.REDEEM_VOUCHER.AUTO_SELECT_LOCATION',
      },
      CONFIRM_AT_LOCATION: {
        action: 'CLICK',
        label: 'VOUCHERS.REDEEM_VOUCHER.CONFIRM_AT_LOCATION',
      },
      CANCEL_AT_LOCATION: {
        action: 'CLICK',
        label: 'VOUCHERS.REDEEM_VOUCHER.CANCEL_AT_LOCATION',
      },
      ENTER_SALE_AMOUNT: {
        action: 'CLICK',
        label: 'VOUCHERS.REDEEM_VOUCHER.ENTER_SALE_AMOUNT',
      },
      REDEEM: {
        action: 'CLICK',
        label: 'VOUCHERS.REDEEM_VOUCHER.REDEEM',
      },
      SKIP_AND_REDEEM: {
        action: 'CLICK',
        label: 'VOUCHERS.REDEEM_VOUCHER.SKIP_AND_REDEEM',
      },
      SHOW_SCANCODE: {
        action: 'CLICK',
        label: 'VOUCHERS.REDEEM_VOUCHER.SHOW_SCANCODE',
      },
      CLOSE_SCANCODE: {
        action: 'CLICK',
        label: 'VOUCHERS.REDEEM_VOUCHER.CLOSE_SCANCODE',
      },
    },
  },

  SURVEY: {
    BUTTON: {
      GET_STARTED: {
        action: 'CLICK',
        label: 'SURVEY.BUTTON.GET_STARTED',
      },
      DINE_IN: {
        action: 'CLICK',
        label: 'SURVEY.BUTTON.DINE_IN',
      },
      DELIVERY: {
        action: 'CLICK',
        label: 'SURVEY.BUTTON.DELIVERY',
      },
      TAKE_AWAY: {
        action: 'CLICK',
        label: 'SURVEY.BUTTON.TAKE_AWAY',
      },
      LOCATION: {
        action: 'CLICK',
        label: 'SURVEY.BUTTON.LOCATION',
      },
      DELIVERY_PLATFORM: {
        action: 'CLICK',
        label: 'SURVEY.BUTTON.DELIVERY_PLATFORM',
      },
      RATING: {
        action: 'CLICK',
        label: 'SURVEY.BUTTON.RATING',
      },
      INPUT_MESSAGE: {
        action: 'CLICK',
        label: 'SURVEY.BUTTON.INPUT_MESSAGE',
      },
      THUMBS_UP: {
        action: 'CLICK',
        label: 'SURVEY.BUTTON.THUMBS_UP',
      },
      THUMBS_DOWN: {
        action: 'CLICK',
        label: 'SURVEY.BUTTON.THUMBS_DOWN',
      },
      VISIT_TIME: {
        action: 'CLICK',
        label: 'SURVEY.BUTTON.VISIT_TIME',
      },
      VISIT_DATE: {
        action: 'CLICK',
        label: 'SURVEY.BUTTON.VISIT_DATE',
      },
      ORDER_REFERENCE: {
        action: 'CLICK',
        label: 'SURVEY.BUTTON.ORDER_REFERENCE',
      },
      UPLOAD_PICTURES: {
        action: 'CLICK',
        label: 'SURVEY.BUTTON.UPLOAD_PICTURES',
      },
      SUBMIT_RATE_EXPERIENCE: {
        action: 'CLICK',
        label: 'SURVEY.BUTTON.SUBMIT_RATE_EXPERIENCE',
      },
      ENTER_NAME: {
        action: 'CLICK',
        label: 'SURVEY.BUTTON.ENTER_NAME',
      },
      ENTER_EMAIL: {
        action: 'CLICK',
        label: 'SURVEY.BUTTON.ENTER_EMAIL',
      },
      ENTER_PHONE: {
        action: 'CLICK',
        label: 'SURVEY.BUTTON.ENTER_PHONE',
      },
      AGREE_TERMS_CONDITIONS: {
        action: 'CLICK',
        label: 'SURVEY.BUTTON.AGREE_TERMS_CONDITIONS',
      },
      AGREE_TO_RECEIEVE_MARKETING_UPDATES: {
        action: 'CLICK',
        label: 'SURVEY.BUTTON.AGREE_TO_RECEIEVE_MARKETING_UPDATES',
      },
      SUBMIT: {
        action: 'CLICK',
        label: 'SURVEY.BUTTON.SUBMIT',
      },
      GET_YOUR_OFFER: {
        action: 'CLICK',
        label: 'SURVEY.BUTTON.GET_YOUR_OFFER',
      },
      SELECT_STAFF: {
        action: 'CLICK',
        label: 'SURVEY.BUTTON.SELECT_STAFF',
      },
      STAFF_SKIP: {
        action: 'CLICK',
        label: 'SURVEY.BUTTON.STAFF_SKIP',
      },
      STAFF_RATING: {
        action: 'CLICK',
        label: 'SURVEY.BUTTON.STAFF_RATING',
      },
      STAFF_RATING_SUBMIT: {
        action: 'CLICK',
        label: 'SURVEY.BUTTON.STAFF_RATING_SUBMIT',
      },
      SHARE_REVIEW_ON_SOCIAL: {
        action: 'CLICK',
        label: 'SURVEY.BUTTON.SHARE_REVIEW_ON_SOCIAL',
      },
      SHARE: {
        GOOGLE: {
          action: 'CLICK',
          label: 'SURVEY.BUTTON.SHARE.GOOGLE',
        },
      },
    },
  },
};
