import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import { browserName, isAndroid } from 'react-device-detect';
import { Modal } from 'antd';
import Button from 'components/Button';
import { isClient } from 'utils/common';
import useGoogleFonts from 'hooks/useGoogleFonts';

interface MetaHeaderProps {
  title: string;
  image: string | undefined;
  pixelId?: string | null;
  redirectBrowser?: boolean;
  fontFamilies?: string[];
}

const MetaHeader = ({ title, image, pixelId = '', redirectBrowser = false, fontFamilies = [] }: MetaHeaderProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  useEffect(() => {
    setTimeout(() => {
      if (browserName === 'Instagram' && isAndroid && redirectBrowser) {
        showModal();
      }
    }, 500);
  }, []);

  const micrositeUrl = isClient() ? window?.location?.href?.replace(/(^\w+:|^)\/\//, '') : '';
  useGoogleFonts(fontFamilies);

  return (
    <>
      <Head>
        <title>{title}</title>
        {image && (
          <>
            <link rel="apple-touch-icon" sizes="180x180" href={image} />
            <link rel="icon" type="image/png" sizes="32x32" href={image} />
            <link rel="icon" type="image/png" sizes="16x16" href={image} />
            <meta property="og:image" itemProp="image" content={image} />
          </>
        )}

        <meta property="og:title" content={title} />

        {/* Global Site Code Pixel - Facebook Pixel */}
        <script
          dangerouslySetInnerHTML={{
            __html: `
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', ${pixelId});
              `,
          }}
        />

        <noscript>
          <img
            alt="Facebook Pixel"
            height="1"
            width="1"
            style={{ display: 'none' }}
            src={`https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1`}
          />
        </noscript>
      </Head>
      <Modal title="" visible={isModalVisible} closable={false} footer={null}>
        <div className="text-center">
          <h3 className="mb-20">For a better experience, let’s open this link in the Chrome app</h3>
          <Button
            color="primary"
            size="middle"
            href={`intent://${micrositeUrl}/#Intent;scheme=https;package=com.android.chrome;end`}
          >
            Proceed
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default MetaHeader;
