import React from 'react';
import styled from 'styled-components';
import { HomeIcon, FoodMenuWhiteIcon } from 'assets/svg';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { PageHeader } from 'antd';

interface Theme {
  headerBgColor?: string;
  headerTextColor?: string;
}

interface HeaderProps {
  brandDetails: { logoUrl: string | null; name: string };
  hideMenuLink?: boolean;
  fixedHeader?: boolean;
  theme?: Theme;
  hideHome?: boolean;
}

const Header = ({ brandDetails, hideMenuLink = false, fixedHeader = true, theme, hideHome = false }: HeaderProps) => {
  const router = useRouter();

  const { slug } = router.query;

  return (
    <HeaderWrap $sticky={fixedHeader} $theme={theme}>
      <PageHeader
        className="site-page-header"
        avatar={
          brandDetails?.logoUrl
            ? {
                icon: (
                  <img
                    src={brandDetails.logoUrl}
                    className="header-logo"
                    onClick={() => router.replace(`/${slug}`)}
                    alt=""
                  />
                ),
                shape: 'square',
                size: 'large',
              }
            : undefined
        }
        title={brandDetails?.logoUrl ? '' : <div onClick={() => router.replace(`/${slug}`)}>{brandDetails?.name}</div>}
        extra={
          <HeaderActions hideMenuLink={hideMenuLink}>
            {!hideHome && (
              <Link href={`/${slug}`} passHref>
                <HeaderButton $theme={theme}>
                  <HomeIcon />
                  Home
                </HeaderButton>
              </Link>
            )}

            {!hideMenuLink && (
              <Link href={`/${slug}/menus`} passHref>
                <HeaderButton $theme={theme}>
                  <FoodMenuWhiteIcon />
                  Menu
                </HeaderButton>
              </Link>
            )}
          </HeaderActions>
        }
      />
    </HeaderWrap>
  );
};

export default Header;

interface HeaderWrapProps {
  $theme?: Theme;
  $sticky?: boolean;
}

const HeaderWrap = styled.header<HeaderWrapProps>`
  border-bottom: 1px solid var(--grey-light);
  background: ${(props) => props.$theme?.headerBgColor || 'var(--white)'};

  ${({ $sticky }) =>
    $sticky &&
    `
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
  `}

  .ant-page-header-heading-extra {
    display: flex;
    justify-content: center;
  }

  .ant-avatar {
    background: transparent;
  }

  .header-logo,
  .ant-page-header-heading-title {
    cursor: pointer;

    > div {
      color: ${(props) => props.$theme?.headerTextColor || 'var(--grey-dark)'};
    }
  }
`;

interface HeaderActionsProps {
  hideMenuLink: boolean;
}

const HeaderActions = styled.div<HeaderActionsProps>`
  display: flex;
  flex-direction: row;
  align-items: center;

  > a:first-child {
    margin-right: ${(props) => (props.hideMenuLink ? '0px' : '20px')};
  }
`;

interface HeaderButtonProps {
  $theme?: Theme;
}

const HeaderButton = styled.a<HeaderButtonProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  font-size: 16px;
  line-height: 20px;
  color: ${(props) => props.$theme?.headerTextColor || 'var(--grey-dark)'};

  svg path {
    stroke: ${(props) => props.$theme?.headerTextColor || 'var(--grey-dark)'};
  }

  :hover {
    color: ${(props) => props.$theme?.headerTextColor || 'var(--grey-dark)'};
  }

  svg {
    margin-right: 10px;
  }
`;
