import React from 'react';
import styled from 'styled-components';
import { MomosLogoImg } from 'assets/svg';
import { isClient } from 'utils/common';
import { MOMOS_WEBSITE_URL } from 'constant';

interface PoweredByMomosProps {
  color?: string;
  className?: string;
}

const PoweredByMomos = ({ color, className }: PoweredByMomosProps) => {
  const navigateToMomos = () => {
    if (isClient()) {
      window.open(MOMOS_WEBSITE_URL, '_blank');
    }
  };

  return (
    <PoweredByMomosWrap $color={color} onClick={navigateToMomos} className={className}>
      Powered by <MomosLogoImg />
    </PoweredByMomosWrap>
  );
};

interface PoweredByMomosWrapProps {
  $color?: string;
}

const PoweredByMomosWrap = styled.div<PoweredByMomosWrapProps>`
  font-family: var(--font-family-medium);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${(props) => props.$color || 'inherit'};
  padding-bottom: 20px;
  padding-top: 40px;

  svg {
    margin-left: 10px;

    ${({ $color }) =>
      $color &&
      `
    path {
      fill: ${$color};
    }
  `}
  }
`;

export default PoweredByMomos;
