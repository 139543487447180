import React from 'react';
import { Button as AntButton } from 'antd';
import styled from 'styled-components';

const Button = ({ type = 'primary', htmlType = 'button', children, className, disabled, style = {}, ...props }) => {
  return (
    <CustomAntButton
      size="large"
      type={type}
      htmlType={htmlType}
      disabled={disabled}
      className={className}
      style={{ minWidth: 150, ...style }}
      {...props}
    >
      {children}
    </CustomAntButton>
  );
};

const CustomAntButton = styled(AntButton)`
  &.ant-btn-lg {
    height: 50px;
  }

  &.ant-btn-primary:not([disabled]) {
    color: var(--white);
    background: var(--orange);
    border-color: var(--orange);
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  }

  &.ant-btn-primary span {
    color: var(--white);
    font-weight: 600;
  }
`;

export default Button;
